<template>
  <div>
    <ListHeader entity-name="frequentQuestion" router-name="FrequentQuestionNew"></ListHeader>
    <Modal model="frequentQuestion" @confirm-deletion="deleteRecord"/>
    <div class="default-according style-1 faq-accordion default-accordion" id="accordionoc">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 m-b-20" v-for="(frequentQuestion, id) in records.items" :key="id">
            <b-card no-body>
              <CardHeader :id="id" model-name="FrequentQuestion" :record="frequentQuestion"
                          @sendRecord="sendRecord"></CardHeader>
              <b-collapse :id="`${id}`" role="tabpanel">
                <b-card-body class="pre">
                  <p> {{ frequentQuestion.answer }} </p>
                  <span v-for="(tag, id) in frequentQuestion.tagList" :key="id" class="tag-item m-r-10">
                    {{ tag }}
                  </span>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div class="pagination" v-if="countNotZero">
            <pagination :options="options" :pages="pages" @paginate="paginate"/>
          </div>
          <div v-else>
            {{ $t('messages.error.noContent') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import ListsMixin from 'mixins/ListsMixin';

export default {
  name: 'FrequentQuestionList',
  data() {
    return {
      modelName: 'frequentQuestion'
    };
  },
  computed: {
    ...mapGetters({
      records: 'frequentQuestions'
    })
  },
  methods: {
    ...mapActions({
      getRecords: 'getFrequentQuestions',
      destroyRecord: 'destroyFrequentQuestion'
    })
  },
  mixins: [ListsMixin]
};
</script>
